import { styled } from "@linaria/react";
import React from "react";
import { Link } from "react-router-dom";

const StyledProfile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f6;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  overflow: hidden;
`;

const HeaderProfile = () => <StyledProfile></StyledProfile>;

export { HeaderProfile };
