import { Suspense } from "react";
import { BrowserRouter, Router } from "react-router-dom";
import { Spin } from "antd";
import React from "react";
import { browserHistory } from "shared/history";

export const withRouter = (component: () => React.ReactNode) => () =>
  (
    <Router history={browserHistory}>
      <Suspense
        fallback={<Spin delay={300} className="overlay" size="large" />}
      >
        {component()}
      </Suspense>
    </Router>
  );
