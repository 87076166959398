import { useEffect } from "react";
import { errorNotify } from ".";

/** Хук для показа нотификаций с ошибкой
 * Можно применять для ошибок из useRequest
 */
export const useErrorNotify = (
  error?: string | string[] | null,
  title?: string
) => {
  useEffect(() => {
    if (error) {
      errorNotify(
        title || "Ошибка",
        Array.isArray(error) ? error.join(", ") : error
      );
    }
  }, [error, title]);
};
