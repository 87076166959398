import React from "react";
import { ConfigProvider } from "antd";
import 'moment/locale/ru';
import ruRU from "antd/lib/locale/ru_RU";
import moment from "moment";

moment.locale('ru');


export const withAntConfig = (component: () => React.ReactNode) => () =>
  <ConfigProvider locale={ruRU}>{component()}</ConfigProvider>;
