import { styled } from "@linaria/react";
import React from "react";
import { Link } from "react-router-dom";

const HeaderLogo = () => (
  <Link to="/">
    <img
      src="/img/headlogo.png"
      width="64"
      height="36"
      alt="Логотип Active Friends"
    />
  </Link>
);

export { HeaderLogo };
