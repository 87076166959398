import { appDomain } from "./app";
import { TokenMessage, updateToken } from "api";
import { OrgData, orgGetHandle } from "api/org";
import { TokenStorage } from "api/tokenStorage";
import { getHobbiesFx } from "domain/hobbie/model";
import {
  combine,
  createEffect,
  createEvent,
  createStore,
  forward,
  sample,
} from "effector";
import { createGate } from "effector-react";

export const doLogout = appDomain.createEvent();

export const doLogoutAndRedirect = appDomain.createEvent();

const logoutFx = appDomain.createEffect((param?: { isRedirect?: boolean }) => {
  TokenStorage.removeRefreshToken();
  TokenStorage.removeToken();

  if (param?.isRedirect) {
    window.location.assign("/login");
  }
});

sample({
  clock: doLogoutAndRedirect,
  fn: () => ({ isRedirect: true }),
  target: logoutFx,
});

forward({
  from: doLogout,
  to: logoutFx,
});

// при запуске приложения
export const SessionGate = createGate();

// проверяем наличие refresh token-а и если он есть - обновляем токены и получаем данные о пользователе
export const getTokenFx = appDomain.createEffect(async () => {
  return await updateToken();
});

forward({
  from: SessionGate.open,
  to: getTokenFx,
});

// если залогинен - сохраняем данные пользователя
export const $sessionToken = appDomain
  .createStore<TokenMessage | null>(null)
  .on(getTokenFx.doneData, (_, token) => token)
  .on(doLogout, () => null);
export const $sessionData = $sessionToken.map((s) => s?.user);

export const $permissions = $sessionData.map((s) =>
  s?.permissions.map((p) => p.name)
);

// запрашиваем виды спорта
forward({
  from: $sessionData,
  to: getHobbiesFx,
});

export const $currentOrg = createStore<OrgData | null>(null);

const getCurrentOrgFx = createEffect(orgGetHandle);

sample({
  clock: getCurrentOrgFx.doneData,  
  target: $currentOrg,
});

forward({
  from: $sessionData,
  to: getCurrentOrgFx
})

$sessionToken.reset(doLogout);

// сохраняем ошибку
export const $authErrorData = appDomain
  .createStore<Error | null>(null)
  .on(getTokenFx.failData, (_, err) => err)
  .reset(getTokenFx.pending);

// индикатор проверки токена
//(при перезагрузке страницы чтобы дождаться рефреша токена,
//а не сразу вываливаться в логин)
const $isTokenCheck = appDomain.createStore<boolean>(true);

// ждем результата рефреша токена и отключаем isTokenCheck
sample({
  clock: [$sessionData, $authErrorData],
  fn: () => false,
  target: $isTokenCheck,
});

$authErrorData.watch(console.log);

export const $isAuth = $sessionData.map((state) => !!state);

export const $sessionStore = combine({
  isTokenCheck: $isTokenCheck,
  isAuth: $isAuth,
});
