import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import { Spin } from "antd";
import React from "react";
import { errorNotify } from "shared/notify";

type Props = {
  fallback: React.ReactNode;
  children: React.ReactNode;
  onError?: (e: Error) => void;
};

type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error) {
    const { props } = this;

    if (props.onError) {
      props.onError(error);
    }
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  render() {
    const {
      state: { hasError },
      props: { fallback, children },
    } = this;

    if (hasError) {
      return fallback;
    }

    return children;
  }
}

export const withError = (component: () => React.ReactNode) => () => {
  const handleError = (e: Error) => {
    errorNotify("Ошибка приложения", e.message);
  };
  return (
    <ErrorBoundary fallback={<p>Ошибка...</p>} onError={handleError}>
      {component()}
    </ErrorBoundary>
  );
};
