import { HobbieItem } from "./hobbie";
import { Photo } from "./media";
import { request } from "./request";
import { objToQuery } from "./request/lib";
import { UserType } from "./user";
import { FormGeo } from "domain/geo/types";
import { BaseList } from "shared/form/list";

export type OrgType = UserType;

export type OrgCity = {
  id: IdNum;
  name: string;
};
export enum OrgStatusEnum {
  "notViewed",
  "viewed",
  "accepted",
  "canceled",
}
export type OrgStatus = keyof typeof OrgStatusEnum;

export type OfferFile = { url: string; fileName: string; size: number };

export type OrgData = {
  id: IdNum;
  email: EmailStr;
  phone: PhoneStr;
  userType: OrgType;
  isBlocked: boolean;
  hobbies?: HobbieItem[];
  updPasswordDate: DateTimeStr;
  orgProfile?: {
    city: OrgCity;
    createdAt: DateTimeStr;
    updatedAt: DateTimeStr;
    deletedAt: DateTimeStr;
    company: string;
    site: string;
    description: string;
    photo: Photo;
    form: string;
    legalName: string;
    shortName: string;
    inn: string;
    kpp?: string;
    ogrn: string;
    legalAddress: string;
    legalZip: string;
    legalCity: string;
    contactName: string;
    contactSurname: string;
    contactPosition: string;
    contactPhone: string;
    contactPhoneAdd: string;
    status: OrgStatus;
    profiled: boolean;
    birth: string;
    gender: string;
    isOfferAccepted?: boolean;
    headFirstName: string;
    headMiddleName: string;
    headLastName: string;
    headBirth: string;
    headPhone: string;
    offerFile?: OfferFile;
  };
  paymentDetails?: PaymentDetailsData;
};

export type PaymentDetailsData = {
  userId?: number;
  bank?: string;
  bik?: string;
  ks?: string;
  rs?: string;
  // headBirth: string;
  // headFirstName: string;
  // headLastName: string;
  // headMiddleName: string;
  // headPhone: string;
};

export type OrgEditData = {
  id?: IdNum;
  geo?: FormGeo;
  photoId?: IdNum;
  photo?: Photo;
  company?: string;
  cityId?: string[];
  hobbiesIds?: number[];
  site?: string;
  description?: string;
  form?: string;
  legalName?: string;
  inn?: string;
  ogrn?: string;
  legalAddress?: string;
  contactName?: string;
  contactSurname?: string;
  contactPosition?: string;
  contactPhone?: string;
  contactPhoneAdd?: string;
  birth?: string;
  gender?: string;
  offerFile?: OfferFile;
};

export type UserProfile = {
  name: string;
  surname: string;
};

export type OrgFilter = {
  search?: string;
  offset?: number;
  limit?: number;
  companyName?: string;
  inn?: string;
  ogrn?: string;
  profiled?: boolean;
  isStudOrg?: boolean;
  status?: OrgStatus[];
  cityId?: number;
  birth?: string;
  gender?: string;
};

export type OrgList = BaseList & {
  rows?: OrgData[];
};

export type Creator<T extends OrgType> = {
  id: number;
  userType: T;
} & { company: string } & OrgData & { profile?: UserProfile };

export type AuthorWithPhoto = Creator<"org" | "admin"> & { photo: Photo };

export type Media = Photo & { author: Creator<"org"> };

const ORG_LIST_HANDLE = "users/org/byAdmins/list";
const ORG_HANDLE = "users/org";
const PAYMENT_DETAILS_HANDLE = "payment-details";
const ORG_ACCEPT_OFFER_HANDLE = "users/org/acceptOffer";

/** список организаторов */
export const orgListHandle = (data?: OrgFilter) => {
  return request<OrgList>(`${ORG_LIST_HANDLE}${objToQuery(data)}`, true);
};

/** получить организатора */
export const orgGetHandle = () => request<OrgData>(`${ORG_HANDLE}/me`, true);

/** Редактирование организатора */
export const orgUpdateHandle = (data: OrgEditData) =>
  request<OrgData>(`${ORG_HANDLE}/me`, true, {
    method: "PUT",
    body: JSON.stringify(data),
  });

/** сохранение  платежных реквизитов */
export const orgPaymentDetailSaveHandle = (data: PaymentDetailsData) =>
  request<PaymentDetailsData>(
    `${PAYMENT_DETAILS_HANDLE}/${data.userId}`,
    true,
    {
      method: "POST",
      body: JSON.stringify(data),
    }
  );

export const acceptOfferHandle = () =>
  request<void>(`${ORG_ACCEPT_OFFER_HANDLE}`, true, {
    method: "PUT",
  });
