import { API_URL } from "./config";
import { TokenMessage } from "api/auth";
import { TokenStorage } from "api/tokenStorage";

const REFRESH_TOKEN_URL = "auth/refreshAuth";

export class RequestError extends Error {
  constructor(
    readonly errors: string[],
    readonly status: number,
    readonly key?: number
  ) {
    super(errors.join(", "));
    this.name = String(status);
    this.key = key ?? new Date().getTime();
  }
}

export const getApiUrl = (url: string) => `${API_URL}/${url}`;

/** Перезапросить токен, если срок действия истек */
export const updateToken = async (): Promise<TokenMessage> => {
  const refreshToken = TokenStorage.getRefreshToken();
  if (!refreshToken) {
    throw new RequestError(["Пользователь не авторизован"], 401);
  }
  const refreshResponse = await fetch(getApiUrl(REFRESH_TOKEN_URL), {
    method: "POST",
    body: JSON.stringify({ refreshToken }),
    headers: { "Content-Type": "application/json" },
  });
  if (refreshResponse.status !== 200) {
    throw new RequestError(["Пользователь не авторизован"], 401);
  } else {
    const dt = (await refreshResponse.json()) as TokenMessage;
    TokenStorage.setToken(dt.accessToken);
    TokenStorage.setRefreshToken(dt.refreshToken);
    return dt;
  }
};

/** защищенный запрос с перезапросом при устаревшем токине */
export const requestSec = async (
  reqFunc: (headers: Headers) => Promise<Response>,
  initHeaders?: Headers
) => {
  const token = TokenStorage.getToken();
  // если запрос безопасный, а пользователь ниразу не авторизовывался, то сразу его выкидывать без запроса
  if (!token) {
    throw new RequestError(["Пользователь не авторизован"], 401);
  }
  const headers = new Headers(initHeaders);
  headers.set("Authorization", `Bearer ${token}`);
  let response = await reqFunc(headers);
  // если запрос безопасный, и мы получили 401, пробуем зарефрешить токен
  if (response.status === 401) {
    const newToken = await updateToken();
    headers.set("Authorization", `Bearer ${newToken.accessToken}`);
    return await reqFunc(headers);
  } else {
    return response;
  }
};

/**
 * доступные методы запросов на сервер
 */
export type RequestMethod = "GET" | "POST" | "PATCH" | "DELETE" | "PUT";
/**
 * Организация запросов на серве
 * @param  {string} url
 * @param  {boolean} isSecure безопасный запрос (для авторизованного пользователя)
 * @param  {RequestInit} init
 * @returns Promise<T>
 */
export const request = async <T>(
  url: string,
  isSecure?: boolean,
  init?: RequestInit
): Promise<T> => {
  const headers = new Headers(init?.headers);
  headers.append("Content-Type", "application/json");

  let response: Response;
  if (isSecure) {
    const fetchThunk = (headers: Headers) =>
      fetch(getApiUrl(url), { ...init, headers });
    response = await requestSec(fetchThunk, headers);
  } else {
    response = await fetch(getApiUrl(url), { ...init, headers });
  }

  // обработать ошибки
  if (response.status === 401) {
    window.location.assign("/login");
  }
  if (response.status < 200 || response.status >= 400) {
    const dt = await response.json();
    if (dt.errors) {
      throw new RequestError(dt.errors, response.status);
    }
    throw new RequestError(["Неизвестная ошибка"], response.status);
  }
  try {
    return (await response.json()) as T;
  } catch (error) {
    console.log(error);
    return null as unknown as T;
  }
};

export const downloadSecFile = async (url: string, filename: string) => {
  const fetchThunk = (headers: Headers) => fetch(getApiUrl(url), { headers });
  const headers = new Headers();
  const response = await requestSec(fetchThunk, headers);
  response.blob().then((blob) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.target = "_blank";
    a.download = filename;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
  });
};
