import { HobbieItem, hobbieListHandle, HobbieType } from "api/hobbie";
import { createEffect, createStore } from "effector";

export const getHobbiesFx = createEffect(hobbieListHandle);

const hobbieConvert = (list: HobbieType[] | null): HobbieItem[] | null => {
  if (!list) return null;
  return list.filter((el) => el.parentId === null);


  // const toFlat = (oldList?: HobbieType[]): HobbieItem[] => {
  //   if (!oldList) return [];
  //   let tmpList: HobbieItem[] = [];
  //   for (const h of oldList) {
  //     tmpList.push({ id: h.id, name: h.name });
  //     if (h.children) {
  //       tmpList = [...tmpList, ...toFlat(h.children)];
  //     }
  //   }
  //   return tmpList;
  // };
  // return toFlat(list);
};

export const $hobbieList = createStore<HobbieItem[] | null>(null).on(
  getHobbiesFx.doneData,
  (_, hobbie) => hobbieConvert(hobbie)
);

// список для селекта
// export const $hobbieOptions = $hobbieList.map((state) =>
//   state?.map((h) => ({ label: h.name, value: h.id }))
// );
