import React from "react";
import "./index.less";
import { Routing } from "pages";
import { withProviders } from "./providers";
import { $sessionStore, SessionGate } from "store/session";
import { useGate, useStore } from "effector-react";
import { Layout, Spin } from "antd";
import { Content, Footer } from "antd/lib/layout/layout";
import moment from "moment";
import "moment/dist/locale/ru";

moment.locale("ru-RU");

function App() {
  useGate(SessionGate);
  const session = useStore($sessionStore);
  return (
    <div className="App">
      <Layout className="layout">
        {session.isTokenCheck ? <Spin size="large" /> : <Routing />}
        <Footer className="footer">© Active Friends</Footer>
      </Layout>
    </div>
  );
}

export default withProviders(App);
