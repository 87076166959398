import { styled } from "@linaria/react";
import React from "react";
import { MainMenu } from "../../mainmenu";
import { HeaderEvents } from "./events";
import { HeaderLogo } from "./logo";
import { HeaderProfile } from "./profile";

const HeaderContainer = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  box-shadow: 0px 6px 60px rgba(32, 39, 61, 0.04);
  border-radius: 16px;
  width: 100%;
  height: 72px;
  padding: 0 24px;
`;

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: 12px;
  gap: 42px;
`;

const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  gap: 16px;
`;

const PrivateHeader = () => (
  <HeaderContainer>
    <HeaderLeft>
      <HeaderLogo />
      <MainMenu />
    </HeaderLeft>
    <HeaderRight>
      <HeaderEvents />
      <HeaderProfile />
    </HeaderRight>
  </HeaderContainer>
);

export { PrivateHeader };
