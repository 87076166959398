import { styled } from "@linaria/react";
import { Button, Menu } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import { doLogoutAndRedirect } from "store/session";
import React from "react";
import { Link } from "react-router-dom";
import { PrivateHeader } from "shared/ui/layout/privatheader";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 36px;
`;

const ContentContainer = styled.div`
  padding-top: 36px;
`;

export const PrivateLayout = ({ children }: { children: JSX.Element }) => {
  return (
    <LayoutContainer>
      <PrivateHeader />
      <ContentContainer>
        <Content className="fullContent">{children}</Content>
      </ContentContainer>
    </LayoutContainer>
  );
};
