import { styled } from "@linaria/react";
import React from "react";
import { Link } from "react-router-dom";

const StyledEvents = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f2f4f6;
  border-radius: 12px;
  width: 48px;
  height: 48px;
  overflow: hidden;
`;

const HeaderEvents = () => (
  <StyledEvents to="/events">
    <img src="/img/headevents.svg" width="24" height="24" alt="" />
  </StyledEvents>
);

export { HeaderEvents };
