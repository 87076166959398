import { css } from "@linaria/core";
import { styled } from "@linaria/react";
import { mainMenu } from "app/mainMenu";
import React from "react";
import { NavLink } from "react-router-dom";

const MenuContainer = styled.header`
  display: flex;
  align-items: center;
  gap: 32px;
  height: 100%;
`;

const MenuItem = css`
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  height: 100%;
  &[href] {
    color: #20273d;
    text-decoration: none;
  }
  &.current {
    color: #448aff;
    border-bottom: 2px solid #448aff;
  }
`;

const MainMenu = () => (
  <MenuContainer>
    {mainMenu.map((i, n) => (
      <NavLink
        key={n}
        className={MenuItem}
        exact={true}
        activeClassName="current"
        to={i.to}
      >
        {i.title}
      </NavLink>
    ))}
  </MenuContainer>
);

export { MainMenu };
