export class TokenStorage {
  public static getToken(): string | null {
    return localStorage.getItem("token");
  }

  public static setToken(token: string) {
    localStorage.setItem("token", token);
  }

  public static setTokens(token: string, refreshToken: string) {
    TokenStorage.setToken(token);
    TokenStorage.setRefreshToken(refreshToken);
  }

  public static removeToken() {
    localStorage.removeItem("token");
  }

  public static getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  public static removeRefreshToken() {
    localStorage.removeItem("refreshToken");
  }

  public static setRefreshToken(refreshToken: string) {
    localStorage.setItem("refreshToken", refreshToken);
  }
}
