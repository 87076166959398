import { request } from "./request";
import { UserType } from "./user";

export type AccessToken = string;
export type RefreshToken = string;

const LOGIN_HANDLE = "auth/signin";

const PHONE_LOGIN_HANDLE = "auth/org/signinByPhoneNumber";

const PASSWORD_RESET_HANDLE = "users/password";
const EMAIL_RESET_HANDLE = "auth/signup/user/email";

export type UserPermission = {
  id: IdNum;
  name: string;
  description: string;
};

/**
 * Данные пользователя при авторизации
 */
export type UserData = {
  id: IdNum;
  userType: UserType;
  phone: PhoneStr;
  email: EmailStr;
  permissions: UserPermission[];
};

/**
 * Ответ, содержащий пользовательские данные и токен авторизации
 *
 */
export type TokenMessage = {
  /** токен пользователя */
  accessToken: AccessToken;
  refreshToken: RefreshToken;
  /** данные пользователя */
  user: UserData;
};

export type LoginData = {
  phoneOrEmail: string;
  password: string;
};

export type AuthPhoneData = {
  phone: string;
};

/** логин по телефону */
export const phoneLoginHandle = (data: AuthPhoneData) =>
  request<void>(PHONE_LOGIN_HANDLE, false, {
    method: "POST",
    body: JSON.stringify({
      ...data,
      fcmToken: "",
      appSignature: "",
      orgType: "org",
    }),
  });

/** проверка кода по телефону */
export const phoneVirifyHandle = (data: { login: string; code: string }) =>
  request<TokenMessage>("auth/verify", false, {
    method: "POST",
    body: JSON.stringify({
      ...data,
      theme: "registryOrgPhoneVerify",
    }),
  });

/** логин */
export const loginHandle = (data: LoginData) =>
  request<TokenMessage>(LOGIN_HANDLE, false, {
    method: "POST",
    body: JSON.stringify(data),
  });

/** сброс пароля */
export const resetPasswordHandle = (data: {
  newPassword: string;
  confirmPassword: string;
  token: string;
}) =>
  request<{ message: string }>(PASSWORD_RESET_HANDLE, false, {
    method: "PATCH",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
  });

/** сброс email-a */
export const resetEmailHandle = (data: { email: string }) =>
  request<{ message: string }>(EMAIL_RESET_HANDLE, true, {
    method: "POST",
    body: JSON.stringify(data),
  });

export const emailVirifyHandle = (data: {
  login: string;
  code: string;
  theme: string;
}) =>
  request<TokenMessage>("auth/verify", false, {
    method: "POST",
    body: JSON.stringify({
      ...data,
    }),
  });
