import { request } from "./request";

export type HobbieItem = {
  id: IdNum;
  name: string;
  children?: HobbieItem[]
};

export type HobbieEdit = {
  id?: IdNum;
  parentId: IdNum | null;
  name: string;
};

export type HobbieType = {
  id: IdNum;
  name: string;
  parentId: IdNum | null;
  children?: HobbieType[];
};

const HOBBIE_HANDLE = "info/hobbies";

/** список видов спорта */
export const hobbieListHandle = () =>
  request<HobbieType[]>(HOBBIE_HANDLE, true);

/** добавление вида спорта */
export const hobbieAddHandle = (data: HobbieEdit) =>
  request<void>(`${HOBBIE_HANDLE}`, true, {
    method: "POST",
    body: JSON.stringify(data),
  });

/** удаление вида спорта */
export const hobbieDeleteHandle = ({ id }: { id: IdNum }) =>
  request<void>(`${HOBBIE_HANDLE}/${id}`, true, {
    method: "DELETE",
  });

/** редактирование вида спорта */
export const hobbieEditHandle = (data: HobbieEdit) =>
  request<void>(`${HOBBIE_HANDLE}/${data.id}`, true, {
    method: "PATCH",
    body: JSON.stringify(data),
  });
