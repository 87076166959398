import { AccessKeyType, AccessTargetType } from "domain/permission/model";
import { $isAuth } from "store/session";
import { useStore } from "effector-react";
import { PrivateLayout } from "shared/PrivateLayout";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router";

/** Фабрика роутов */
export const createRoute = (path: string, pageComponent: React.ReactNode) => (
  <PrivateRoute key={path} path={path} component={pageComponent} />
);

export const createRoutes = (
  root: string,
  pages: [string, React.ReactNode][]
) =>
  pages.map(([path, pageComponent]) =>
    createRoute(`${root}${path}`, pageComponent)
  );

interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component: any;
}

/**
 * роуты только для залогиненых пользователей, остальных - на страницу логина
 * @param props
 * @returns
 */
const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, ...rest } = props;
  const isLogined = useStore($isAuth);
  return (
    <PrivateLayout>
      <Route
        {...rest}
        render={(routeProps) =>
          isLogined ? (
            <Component {...routeProps} />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: routeProps.location },
              }}
            />
          )
        }
      />
    </PrivateLayout>
  );
};

export default PrivateRoute;
