export const objToQuery = (obj?: {
  [key: string]:
    | string
    | string[]
    | number
    | number[]
    | boolean
    | boolean[]
    | null
    | undefined;
}) => {
  if (!obj) {
    return "";
  }
  let urlparams = "";
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const element = obj[key];
      if (typeof element === "undefined" || element === "") {
        continue;
      }
      if (Array.isArray(element)) {
        urlparams +=
          "&" +
          element
            .map((el: any) => `${key}=${encodeURIComponent(el)}`)
            .join("&");
        continue;
      }
      if (typeof element === "object") {
        continue;
      }
      urlparams += `&${key}=${encodeURIComponent(element)}`;
    }
  }
  urlparams = urlparams.replace(/^&/, "").replace(/&$/, "");
  if (urlparams.length > 0) {
    return `?${urlparams}`;
  }
  return "";
};
