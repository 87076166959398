import React, { lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute, { createRoutes } from "shared/PrivateRoute";

const LoginPage = lazy(() => import("./login"));
const ForgotPassword = lazy(() => import("./forgotPassword"));
const RegistrationPage = lazy(() => import("./registration"));
const EventEditPage = lazy(() => import("./event/edit"));
const EventList = lazy(() => import("./event"));
const EventDetail = lazy(() => import("./event/view"));
const SchedulePage = lazy(() => import("./schedule/view"));
const TrainingEditPage = lazy(() => import("./events2/edit"));
const TrainingViewPage = lazy(() => import("./events2/view"));
const TrainingListPage = lazy(() => import("./events2/list"));
const PaymentDetailsPage = lazy(() => import("./paymentDetails"));
const EmailVerify = lazy(() => import("./emailVerify"));
const Profile = lazy(() => import("./profile"));

const ParticipantsPage = lazy(() => import("./participants"));

export const Routing = () => {
  return (
    <Switch>
      <Route exact path="/login" component={LoginPage} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route exact path="/email-verify" component={EmailVerify} />
      <Route exact path="/registration" component={RegistrationPage} />
      <Route exact path="/event" component={EventList} />
      <Route
        exact
        path="/paymentdetails"
        component={PaymentDetailsPage}
      />
      <Route exact path="/event/add" component={EventEditPage} />
      <Route exact path="/event/edit/:id/:step" component={EventEditPage} />
      <Redirect exact path="/event/edit/:id/" to="/event/edit/:id/1" />
      <Route exact path="/event/view/:id" component={EventDetail} />
      <Route
        exact
        path="/event/view/:id/members"
        component={ParticipantsPage}
      />
       <Route
        exact
        path="/profile"
        component={Profile}
      />
      {/* <PrivateRoute exact path="/schedule" component={SchedulePage} /> */}
      {createRoutes("/event", [
        ["/edit/:id/:step", EventEditPage],
        ["/view/:id", EventDetail],
        ["/add", EventEditPage],
        ["", EventList],
      ])}
      {createRoutes("/training", [
        ["/edit/:id", TrainingEditPage],
        ["/view/:id", TrainingViewPage],
        ["/edit", TrainingEditPage],
        ["", TrainingListPage],
      ])}
      <Redirect to="/login" />
    </Switch>
  );
};
